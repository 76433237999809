import { useEffect } from "react";
import AppController from "legacy-js/core/AppController";

function TrackView({ view, children }) {
    useEffect(() => {
        AppController.setView(view);
    }, [view]);

    return children;
}

export default TrackView;
